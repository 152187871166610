@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@500;600;700;800&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: din-next;
  src: url('/public/Fonts/Din-next.ttf') format('truetype');
}
@font-face {
  font-family: Questrial;
  src: url('/public/Fonts/Questrial.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'din-next',sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFCF8 !important;
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Playfair Display', serif !important;
}
